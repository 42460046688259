@import "./_base";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Ẩn spinner trên Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.App {
    overflow-x: hidden;
    height: auto;
    height: 100vh;
    background: #f2f3f8;

    &__header {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        background: #fff;
        padding-bottom: 30px !important;
        box-shadow: 4px 8px 15px 5px rgba(175, 178, 189, 0.2);

        img {
            max-width: 210px;
        }

        div {
            font-size: 2.2rem;
            color: $primarycolor1;
            font-weight: 600;
        }
    }

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
    }

    &__container {
        padding: 0 4rem 1rem 4rem;
    }

    input {
        border: 1px solid #ccc;

        &:hover {
            border-color: #5da5f7;
            outline: none;
            box-shadow: 0 0 0 2px rgb(94 141 209 / 20%);
        }

        &:focus {
            border-color: #5da5f7;
            outline: none;
            box-shadow: 0 0 0 2px rgb(94 141 209 / 20%);
        }
    }
}

.loading {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
}

.loading svg {
    width: 50px;
}

.Toastify__toast {
    font-size: 15px;
}

.go841353932 {
    width: 4rem !important;
    height: 4rem !important;
}

.go1490302340 div {
    border-top-color: rgb(77, 183, 72) !important;
    border-right-color: rgb(77, 183, 72) !important;
}

input:disabled {
    background-color: #f0f0f0;
    color: #888;
    cursor: not-allowed;
    border: 1px solid #ccc;
}
input:disabled:hover{
    border: none;
    outline: none;
    box-shadow:none;
}

.home_container {
    // max-width: 90%;
    padding: 30px 30px 30px 30px;
    margin: auto;
}