@import "../../_base";
@import url("https://fonts.googleapis.com/css?family=Montserrat|Open+Sans|Roboto");

.searchMedia {
  h1 {
    margin: 0;
    font-weight: unset;
  }

  table {
    z-index: 2;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    box-shadow: 0 2px 15px rgba(64, 64, 64, 0.7);
    border-radius: 12px 12px 0 0;
    overflow: hidden;
    margin-top: 0px;
    margin: auto;
  }

  td,
  th {
    padding: 15px 20px;
    text-align: center;
    white-space: nowrap;
  }

  th {
    background-color: $primarycolor1;
    color: #fafafa;
    font-family: "Open Sans", Sans-serif;
    font-weight: 200;
    font-size: 1.6rem;
    white-space: nowrap;
  }

  tr {
    width: 100%;
    background-color: #fafafa;
    font-family: "Montserrat", sans-serif;
  }

  tr:nth-child(even) {
    background-color: rgb(229, 253, 227);
    color: #fff;
  }

  td {
    font-size: 1.6rem;
    text-align: center;
  }

  .searchMedia-input {
    margin: 10px 0;
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;

    P {
      font-size: 1.8rem;
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    input {
      font-size: 1.6rem;
      border: 1px solid #ccc;
      padding: 5px 10px;
      width: 400px;

      &:hover {
        border-color: #5da5f7;
        outline: none;
        box-shadow: 0 0 0 2px rgb(94 141 209 / 20%);
      }

      &:focus {
        border-color: #5da5f7;
        outline: none;
        box-shadow: 0 0 0 2px rgb(94 141 209 / 20%);
      }
    }
  }

  .scroll-table {
    overflow-y: scroll;
    overflow-x: scroll;
    max-height: 350px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    h3 {
      text-align: center;
      font-size: 2rem;
    }

  }

}