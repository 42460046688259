@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

$primarycolor1: rgb(12, 76, 163);
$primarycolor2: rgb(77, 183, 72);

$colorHover1: rgb(101, 160, 243);
$colorHover2: rgb(133, 223, 128);

$colorBoxShadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
$colorBoxShadow1: $primarycolor1 0px 5px 15px;
$colorBoxShadow2: $primarycolor2 0px 5px 15px;



* {
    box-sizing: border-box;
    // font-size: 62.5%;

}

body {
    font-size: 16px;
}

li,
ul,
p {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-17 {
    font-size: 17px;
}

.fs-18 {
    font-size: 18px;
}

.fs-19 {
    font-size: 19px;
}

.fs-20 {
    font-size: 20px;
}

.fs-21 {
    font-size: 21px;
}

.fs-22 {
    font-size: 22px;
}

.fs-23 {
    font-size: 23px;
}

.fs-24 {
    font-size: 24px;
}

.fs-25 {
    font-size: 25px;
}

.fs-26 {
    font-size: 26px;
}

.fs-27 {
    font-size: 27px;
}

.fs-28 {
    font-size: 28px;
}

.fs-29 {
    font-size: 29px;
}

.fs-30 {
    font-size: 30px;
}

.align-item-center {
    align-items: center;
}

p {
    margin: 0;
}

input {
    margin: 0;
}

.m-r-4 {
    margin-right: 4px;
}

.d-f-s {
    display: flex;
    justify-content: space-between;
}

.d-f-c {
    display: flex;
    justify-content: center;
}

.t_a-c {
    text-align: center;
}

.fw-6 {
    font-weight: 600 !important;
}

.mr-1 {
    margin-right: .25rem;
}

.mr-2 {
    margin-right: .5rem;
}

.mr-3 {
    margin-right: 1rem;
}

.mr-4 {
    margin-right: 1.5rem;
}

.mr-5 {
    margin-right: 3rem;
}

.ml-1 {
    margin-left: .25rem;
}

.ml-2 {
    margin-left: .5rem;
}

.ml-3 {
    margin-left: 1rem;
}

.ml-4 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 3rem;
}

.mb-1 {
    margin-bottom: .25rem;
}

.mb-2 {
    margin-bottom: .5rem;
}

.mb-3 {
    margin-bottom: 1rem;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.mb-5 {
    margin-top: 3rem;
}

.mt-1 {
    margin-top: .25rem;
}

.mt-2 {
    margin-top: .5rem;
}

.mt-3 {
    margin-top: 1rem;
}

.mt-4 {
    margin-top: 1.5rem;
}

.mt-5 {
    margin-top: 3rem;
}

.pl-1 {
    padding-left: .25rem;
}

.pl-2 {
    padding-left: .5rem;
}

.pl-3 {
    padding-left: 1rem;
}

.pl-4 {
    padding-left: 1.5rem;
}

.pl-5 {
    padding-left: 3rem;
}

.pr-1 {
    padding-right: .25rem;
}

.pr-2 {
    padding-right: .5rem;
}

.pr-3 {
    padding-right: 1rem;
}

.pr-4 {
    padding-right: 1.5rem;
}

.pr-5 {
    padding-right: 3rem;
}

.pt-1 {
    padding-top: .25rem;
}

.pt-2 {
    padding-top: .5rem;
}

.pt-3 {
    padding-top: 1rem;
}

.pt-4 {
    padding-top: 1.5rem;
}

.pt-5 {
    padding-top: 3rem;
}

.pb-1 {
    padding-bottom: .25rem;
}

.pb-2 {
    padding-bottom: .5rem;
}

.pb-3 {
    padding-bottom: 1rem;
}

.pb-4 {
    padding-bottom: 1.5rem;
}

.pb-5 {
    padding-bottom: 3rem;
}

.cl-primary1 {
    color: $primarycolor1 !important;

}

.cl-primary2 {
    color: $primarycolor2 !important;

}

.cl-light {
    color: #fff !important;
}

.bg-white {
    background: #fff;
}

.upperCase {
    text-transform: uppercase;
}

.none {
    display: none;
}

.box-shadow {
    box-shadow: 0 0 0 3px rgb(14 112 255 / 20%);
}

// .swal2-popup {
//     font-size: 1.6rem !important;
//     font-family: monospace;
//     color: $primarycolor1;

// }

// .swal2-styled.swal2-confirm {
//     font-size: 1.6rem !important;
// }

// .swal2-success-line-tip {
//     top: 2.875rem !important;
//     left: 0.8125rem !important;
//     width: 1.5625rem !important;
// }

// .swal2-success-line-long {
//     top: 2.375rem !important;
//     right: 0.5rem !important;
//     width: 2.9375rem !important;
// }

// .swal2-icon.swal2-success .swal2-success-ring {
//     top: -0.25rem !important;
//     left: -0.25rem !important;
//     border: 0.25rem solid !important
// }

// .swal2-icon.swal2-success .swal2-success-fix {
//     top: 0.5rem !important;
//     left: 1.625rem !important;
//     width: 0.4375rem !important;
//     height: 5.625rem !important;
// }

// .swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
//     top: -0.4375rem !important;
//     left: -2.0635rem !important;
// }

// .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
//     top: 2.3125rem !important;
//     width: 3.9375rem !important;
//     height: 0.3125rem !important;
// }

// .swal2-html-container {
//     color: $primarycolor2;
//     font-weight: 600;
//     font-size: 2.5rem;
// }

.custom-btn {
    width: 120px;
    font-size: 16px !important;
    height: 40px;
}

.btn-bootstrap-custom {
    font-size: 16px !important;
    padding: 10px 40px !important;
}

.customTable {
    // border-radius: 20px;
    padding: 20px;
    box-shadow: 4px 8px 15px 5px rgba(175, 178, 189, 0.2)
}

.box_content {
    background: #fff;
    border-radius: 20px;
    box-shadow: rgb(12, 76, 163) 0px 5px 15px;
}

.css-1gqmilo-MuiDataGrid-columnHeaderTitle,
.css-1940vl3-MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    font-size: 14px;
    border: none;
}

.MuiDataGrid-columnHeader {
    background: $primarycolor2;
    color: #fff;
}

.MuiButtonBase-root,
.MuiDataGrid-menuIcon {
    display: none;
}

.MuiDataGrid-row {
    border: none;
}

.MuiDataGrid-row:nth-of-type(even) {
    background-color: rgb(241 255 240);
}

.MuiDataGrid-row:hover {
    // background-color: rgba(233, 238, 247, 1);
    background-color: rgb(218, 245, 216) !important;

}

.MuiPaper-root {
    box-shadow: none !important;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.css-1940vl3-MuiDataGrid-root,
.css-e4v5kc-MuiDataGrid-root .MuiDataGrid-virtualScroller {
    border-radius: 12px 12px 0 0 !important;
}

.MuiDataGrid-root:focus,
.MuiDataGrid-cell:focus,
.MuiDataGrid-columnHeader:focus {
    outline: none !important;
}

.css-e4v5kc-MuiDataGrid-root .MuiDataGrid-row--borderBottom .MuiDataGrid-scrollbarFiller {
    background: $primarycolor2;
}

.MuiTablePagination-selectLabel {
    margin: 0 !important;
}

.MuiDataGrid-root {
    border: none !important;
}

/* Bỏ border của các ô (cell) */
.MuiDataGrid-cell {
    border: none !important;
}

/* Bỏ border của header */
.MuiDataGrid-columnHeaders {
    border: none !important;
}

/* Bỏ border của footer */
.MuiDataGrid-footerContainer {
    border: none !important;
}

/* Tùy chỉnh dropdown "Rows per page" */
.MuiDataGrid-select {
    color: #333;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    background-color: #f9f9f9;
}

.MuiDataGrid-selectIcon {
    color: #666;
}

.MuiDataGrid-select:hover {
    background-color: #e0e0e0;
}

/* Tùy chỉnh nút phân trang */
.MuiPaginationItem-root {
    color: #333;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 5px;
}

.MuiPaginationItem-page.Mui-selected {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
}

.MuiPaginationItem-root:hover {
    background-color: #e0e0e0;
}

/* Tùy chỉnh văn bản "1–5 of 92" */
.MuiDataGrid-footer {
    font-size: 14px;
    color: #333;
    padding: 10px;
}

.MuiToolbar-root {
    padding-top: 10px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group input {
    border-color: #e6e6e8;
    color: #575962;
}

.form-group label {
    font-weight: 400;
    font-size: 15.4px;
    margin-bottom: 0.5rem;
}

.btn-radius {
    border-radius: 20px !important;
    padding: 8px 25px !important;
}