@import "../../_base";


.MedicalRegister {
  margin-top: 20px;

  &__top {
    padding: 20px;
    border-radius: 5px;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 15px;

    div {
      display: flex;
      align-items: center;

      label {
        font-size: 1.6rem;
        width: 100px;
        margin-right: 20px;
      }

      input {
        width: 100%;
        flex: 1;
        height: 30px;
        padding-left: 10px;
        font-size: 1.5rem;
        cursor: no-drop;
      }
      #checkbox-uutien{
        width: 20px !important;
        height: 20px !important;
        cursor:pointer;
      }
    }
  }

  &__bottom {
    font-size: 1.5rem;
    margin-top: 10px;
    margin-top: 20px;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: $primarycolor1 0px 5px 15px;
  }

  .MedicalRegister__container {
    margin-top: 1rem;
    margin-bottom: 1srem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .MedicalRegister__option {
    font-size: 1.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 20px 0;

   
    p {
      width: max-content;
      font-size: 1.8rem;
      
      height: 30px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      margin-right: 10px;
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

      &:hover {
        background: $primarycolor1;
        color: #fff;
        transition: .5s all ease;
      }
    }
    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
  .MedicalRegister__layout{
    display: grid;
    // width: 100%;
    grid-template-columns: repeat(3,1fr);
    gap: 20px 15px;
    // max-height: 400px;
    
    li{
        // box-shadow: $primarycolor2 0px 5px 15px;
        padding: 20px;
        text-align: center;
        cursor: pointer;
        border-radius: 20px;
        transition: .3s all ease;
        font-weight: 500;
        border:1px solid $primarycolor2 ;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        
   

        &:hover{
            color: #fff;
            background: $primarycolor2;
            border:1px solid  $primarycolor2;

        }
    }
   
  }
  .MedicalRegister__option-acitve{
    background: $primarycolor1  !important;
    color: #fff;
  }
  .alert_medicalRegister{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: flex;
    justify-content: center;
    visibility: hidden;
    opacity: 0 !important;
    align-items: center;
    transition: .5s all ease;

    .alert_medicalRegister-form{
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 5px;
      background: #fff;
      height: max-content;
      padding: 30px 40px 20px 40px;

      h3{
        font-size: 4rem;
        text-align: center;
        color: $primarycolor1;
        font-weight: 600;
        margin: 0;
      }

      
      div{
        text-align: center;
        margin-top: 40px;
        p{
          font-size: 2.5rem;
          
          &:last-child{
            margin-top: 10px;
          }
        }
      }
      .MedicalRegister_group-button{
        display: flex;
        justify-content: space-between;

        span{
          font-size: 2rem;
          padding: 5px 15px;
          border: 1px solid $primarycolor1;
          border-radius: 5px;
          cursor: pointer;
          color: #fff;
          background-color: $primarycolor1;
          transition: .5s all ease;

          &:hover{
            background-color: $primarycolor2;
            transform: scale(1.1);
          }
        }
      }
    }
    
  }
  .alert_medicalRegister-active{
    visibility: visible;
    opacity: 1 !important;
    
  }
}
